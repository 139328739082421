import {
  Box,
  Flex,
  Group,
  Modal,
  Space,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconHelp } from "@tabler/icons";
import { ReactNode } from "react";
import { HubStateEstimates } from "../../api";
import { CopyButton } from "../../components/copy";
import { Time } from "../../components/date";
import { GeoJsonMap } from "../../components/map";
import { useConfig } from "../../config";
import { collection, lineWithArrow, point } from "../../geojson";
import { useHashParam } from "../../hooks";
import { tripDeliveries } from "../../methods";
import { getRiderPseudonym } from "../../rider-pseudonyms";
import {
  DeliveredGreen,
  FlinkPink,
  FlinkTripPurple,
  SelectedOrderYellow,
} from "../../themes";
import {
  HubSlug,
  HubState,
  OrderIDSet,
  OutsourcedTrip,
  scheduledTripProposal,
  TimestampEvents,
  Trip,
  TripProposal,
} from "../../types";
import { formatMilliSeconds, isEmptyDate, zip } from "../../utils";
import { totalRidingDurationEstimateInMs } from "../time-estimation";
import {
  DeliveryTrip,
  TripProposalSummary,
  useOrderLabelsSelection,
} from "./hub-trips-and-proposals-overview";
import { HashParams } from "./hubs";
import { TripTimeline } from "./trip-timeline";

export const TripCard = (props: {
  trip?: DeliveryTrip;
  state: HubState;
  setOpenedTripId: (tripID?: string) => void;
  withheldFromPicking: OrderIDSet;
  scheduledTrip?: scheduledTripProposal;
  estimates?: HubStateEstimates;
}) => {
  const [selectedOrderLabels] = useOrderLabelsSelection();
  const isOutsourced = props.trip?.hasOwnProperty("Quote");
  let id = isOutsourced
    ? (props.trip as OutsourcedTrip)?.FulfillmentTripID
    : (props.trip as Trip)?.ID;

  return (
    <>
      {props.trip && (
        <Modal
          opened={!!props.trip}
          onClose={() => props.setOpenedTripId(undefined)}
          size={600}
          title={
            props.trip && id ? (
              <Group spacing={0}>
                <b>Trip Details </b>
                <Text color={"dimmed"} ml={6}>
                  {id!}
                </Text>
                <CopyButton value={id} />
              </Group>
            ) : (
              <b>Trip Proposal Details</b>
            )
          }
        >
          <Stack>
            <Flex dir="column">
              <TripProposalSummary
                trip={props.trip}
                isTripProposal={false}
                hubSlug={props.state.Hub}
                orderLabels={selectedOrderLabels}
                startTimestamp={getStartTimestamp(props.trip)}
                withheldFromPicking={props.withheldFromPicking}
                time={props.state.Timestamp}
                setOpenedTripId={() => {}}
              />
            </Flex>
            <TripMap hub={props.state.Hub} trip={props.trip} height={350} />
            <TripTimeline
              trip={props.trip}
              currentTime={props.state.Timestamp}
              scheduledTrip={props.scheduledTrip}
              estimates={props.estimates}
            />
            <TripData trip={props.trip} scheduledTrip={props.scheduledTrip} />
          </Stack>
        </Modal>
      )}
    </>
  );
};

export const getStartTimestamp = (
  trip?: DeliveryTrip,
): keyof TimestampEvents | undefined => {
  if (!trip?.hasOwnProperty("CompletedAt")) {
    return "pickerAccepted";
  }
  if (isEmptyDate((trip as Trip).CompletedAt)) {
    return "arrived";
  }
};

export const TripMap = (props: {
  hub: HubSlug;
  trip: DeliveryTrip;
  height: number;
}) => {
  const [selectedOrder] = useHashParam(HashParams.SelectedOrder);
  const config = useConfig();
  const hub = config.hubs.get(props.hub)!;

  if (!hub) {
    return (
      <Text size={"lg"} weight={"bold"}>
        Unknown Hub Area
      </Text>
    );
  }

  let tripCompleted = false;
  if (props.trip.hasOwnProperty("CompletedAt")) {
    tripCompleted = !isEmptyDate(
      (props.trip as Trip | OutsourcedTrip).CompletedAt,
    );
  }

  const geojsonData = collection(
    point(hub.default_location, { color: FlinkPink }),
    ...tripDeliveries(props.trip).flatMap((o) => {
      return [
        o.ID === selectedOrder
          ? point(o.DeliveryCoords, {
              selected: true,
              color: SelectedOrderYellow,
            })
          : undefined,
        point(o.DeliveryCoords, {
          orderId: o.ID,
          color:
            o.Timestamps.Events.delivered.Time !== undefined
              ? DeliveredGreen
              : FlinkTripPurple,
        }),
      ];
    }),
    ...zip(tripDeliveries(props.trip), tripDeliveries(props.trip).slice(1))
      .slice(0, -1)
      .flatMap(([o1, o2]) =>
        lineWithArrow([o1.DeliveryCoords, o2.DeliveryCoords], {
          color: tripCompleted ? DeliveredGreen : FlinkTripPurple,
        }),
      ),
  );
  return (
    <GeoJsonMap height={props.height} data={geojsonData} navigation={false} />
  );
};

export const TripData = (props: {
  trip: DeliveryTrip;
  scheduledTrip?: scheduledTripProposal;
}) => {
  const tableContent = () => {
    if (props.trip.hasOwnProperty("Quote")) {
      const trip = props.trip as OutsourcedTrip;
      return (
        <>
          <tr>
            <td>Quote ID</td>
            <td>{trip.Quote.ID}</td>
          </tr>
          <tr>
            <td>Quote Expiry</td>
            <td>
              <Time date={trip.Quote.ExpiresAt} showDate={false} />
            </td>
          </tr>
        </>
      );
    }
    const trip = props.trip as Trip | TripProposal;
    const riderID = props.scheduledTrip
      ? props.scheduledTrip.rider_id
      : (trip as Trip)?.RiderID;

    return (
      <>
        {riderID && (
          <tr>
            <td>{props.scheduledTrip && "Expected "}Rider</td>
            <td>
              <Group
                spacing={0}
                position="right"
                style={{ width: "fit-content" }}
              >
                {getRiderPseudonym(riderID)}
                <Text c="dimmed" fz={10} fw={200} ml={10}>
                  {riderID}
                </Text>
                <CopyButton value={riderID} />
              </Group>
            </td>
          </tr>
        )}
        <tr>
          <td>Total Weight</td>
          <td>{(totalItemWeight(trip) / 1000).toFixed(3)} kg</td>
        </tr>
        <tr>
          <td>
            <Group spacing={4}>
              Routed Duration Estimate
              <IconWithTooltip
                label={
                  <>
                    The estimated riding duration of the trip including the
                    return to hub.
                    {tripDeliveries(trip).length > 1 &&
                      " In parentheses are shown the estimated riding duration savings due to this trip."}
                  </>
                }
              />
            </Group>
          </td>
          <td>
            <Group spacing={4}>
              {formatMilliSeconds(totalRidingDurationEstimateInMs(trip))}
              {tripDeliveries(trip).length > 1 && (
                <>
                  <Space w={6} />
                  {"(" +
                    formatMilliSeconds(
                      sumOfIndividualOrderLegsInMs(trip) -
                        totalRidingDurationEstimateInMs(trip),
                    ) +
                    ")"}
                </>
              )}
            </Group>
          </td>
        </tr>
      </>
    );
  };
  return (
    <Table align="left">
      <tbody>{tableContent()}</tbody>
    </Table>
  );
};

const sumOfIndividualOrderLegsInMs = (trip: DeliveryTrip): number => {
  let total = 0;
  for (let i = 0; i < tripDeliveries(trip).length; i++) {
    const order = tripDeliveries(trip)[i];

    if (!order.RoutedFromHub || !order.RoutedToHub) {
      return NaN;
    }
    total += order.RoutedFromHub + order.RoutedToHub;
  }
  return total * 60 * 1000;
};

const totalItemWeight = (trip: DeliveryTrip): number => {
  return tripDeliveries(trip).reduce((total, delivery) => {
    total += delivery.Items.TotalWeight;
    return total;
  }, 0);
};

const IconWithTooltip = (props: { label: ReactNode }) => (
  <Tooltip label={props.label} multiline>
    <div style={{ display: "inline", marginLeft: "2px" }}>
      <IconHelp size={12} />
    </div>
  </Tooltip>
);
