import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  HoverCard,
  Indicator,
  MultiSelect,
  Space,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBike,
  IconBuildingStore,
  IconBuildingWarehouse,
  IconClick,
  IconDotsVertical,
  IconHandGrab,
  IconHomeCheck,
  IconPaperBag,
  IconSelector,
  IconSkull,
  IconX,
} from "@tabler/icons";
import React, {
  forwardRef,
  Fragment,
  LegacyRef,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { useExtendedOrderInfo, usePickerQueue } from "../../api";
import { OrderIdentifierDisplayContext, useIdOrNumberContext } from "../../app";
import { ArrowSVG } from "../../components/arrow-img";
import { ContainerIconGroup } from "../../components/container-rectangles";
import { CopyButton } from "../../components/copy";
import { Time } from "../../components/date";
import { OrderCard } from "../../components/order-card";
import { Pill } from "../../components/pill";
import { Nullish } from "../../custom-schemas";
import { useHashParam } from "../../hooks";
import { tripDeliveries } from "../../methods";
import { getRiderPseudonym } from "../../rider-pseudonyms";
import { SelectedOrderYellow } from "../../themes";
import {
  Delivery,
  HubSlug,
  HubState,
  IndirectDelivery,
  Order,
  OrderID,
  OrderIDSet,
  OutsourcedTrip,
  PickingTask,
  Rider,
  RiderID,
  RiderStatus,
  scheduledTripProposal,
  TimestampEvents,
  Trip,
  TripProposal,
  Vehicle,
  VehicleID,
} from "../../types";
import {
  durationInMs,
  formatDurationInMinutes,
  formatNumberString,
  getStateColor,
  isEmptyDate,
} from "../../utils";
import { HubAreaView } from "./hub-area-map";
import { getDeliveryTripId, HashParams } from "./hubs";
import { IconWithTooltip, OrderIndicators, TripIndicators } from "./indicators";

const frozenStyle = {
  borderStyle: "dashed",
  borderWidth: "2px",
  borderRadius: "3px",
  paddingInline: 4,
  minHeight: "43px",
  borderColor: "cornflowerblue",
};

const TripBox = (props: { isFrozen: boolean; children: ReactNode }) => (
  <Box
    sx={
      props.isFrozen
        ? frozenStyle
        : {
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "3px",
            paddingInline: 4,
            minHeight: "43px",
          }
    }
  >
    {props.children}
  </Box>
);

const Hoverable = forwardRef(
  (props: { children: ReactNode }, ref: LegacyRef<HTMLDivElement>) => (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  ),
);

const isStuckInPicking = (order: Order, time: Date): boolean => {
  const timestamps = order.Timestamps.Events;
  if (timestamps.pickerAccepted.Time && !timestamps.pickingComplete.Time) {
    return durationInMs(timestamps.pickerAccepted.Time, time) > 15 * 60 * 1000;
  }
  return false;
};

const OrderSummary = (props: {
  order: Order;
  isSelectedOrder?: boolean;
  setSelectedOrder?: (id: Nullish<OrderID>) => void;
  startTimestamp?: keyof TimestampEvents;
  doneTimestamp?: keyof TimestampEvents;
  isWithheld: boolean;
  orderLabels: OrderLabelsSelection;
  time: Date;
  pickerQueue?: PickingTask[];
  scheduledTrip?: scheduledTripProposal;
}): ReactElement => {
  const pickerQueuePosition = props.pickerQueue?.findIndex(
    (task) => task.Order?.ID === props.order.ID,
  );
  const isInPickerQueue: boolean =
    pickerQueuePosition !== -1 && pickerQueuePosition !== undefined;
  const pickingTask = isInPickerQueue
    ? props.pickerQueue![pickerQueuePosition!]
    : undefined;

  const timestamps = props.order.Timestamps.Events;
  const isFilled =
    props.startTimestamp !== undefined &&
    timestamps[props.startTimestamp].Time !== undefined;
  const stateColor = getStateColor(timestamps, props.isWithheld);
  const labelNodes: ReactNode[] = props.orderLabels
    .map((label) =>
      availableOrderLabels[label]?.render(
        props.order,
        props.time,
        stateColor,
        isFilled,
      ),
    )
    .filter((node) => node);

  return (
    <Box>
      <OrderIndicators
        planned={props.order.DeliveryOptionType == "PLANNED"}
        pickingTask={pickingTask}
        taskIndex={pickerQueuePosition}
        temperatureCategories={props.order.Items.TemperatureCategories}
      >
        <HoverCard position="bottom" withArrow shadow="md" openDelay={300}>
          <HoverCard.Target>
            <Hoverable>
              <Pill
                sx={{
                  ...(isFilled
                    ? { backgroundColor: stateColor, color: "white" }
                    : {
                        color: stateColor,
                        borderWidth: "1px",
                        borderColor: stateColor,
                      }),
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  ...(props.isSelectedOrder && {
                    boxShadow: "0px 0px 2px 6px " + SelectedOrderYellow,
                  }),
                }}
                data-dd-action-name={"Order pill"}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(" +
                      (labelNodes.length > 1 ? 2 : 1) +
                      ", minmax(45px, max-content))",
                    gridTemplateRows:
                      "repeat(" + Math.ceil(labelNodes.length / 2) + ", 22px)",
                    padding: "4px",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (!props.setSelectedOrder) return;
                    if (props.isSelectedOrder) {
                      props.setSelectedOrder(undefined);
                    } else {
                      props.setSelectedOrder(props.order.ID);
                    }
                  }}
                >
                  {labelNodes.map((labelNode, i) => (
                    <div
                      key={i}
                      style={{
                        gridColumn: i % 2 === 0 ? 1 : 2,
                        gridRow: Math.floor(i / 2 + 1),
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      {labelNode}
                    </div>
                  ))}
                  {isStuckInPicking(props.order, props.time) && (
                    <div
                      style={{
                        gridColumn: 3,
                        gridRowStart: 1,
                        gridRowEnd: Math.ceil(labelNodes.length / 2) + 1,
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      <IconSkull size={40} />
                    </div>
                  )}
                </div>
              </Pill>
            </Hoverable>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <OrderCard
              order={props.order}
              isWithheld={props.isWithheld}
              isFilled={isFilled}
              pickingTask={pickingTask}
              pickerQueuePosition={pickerQueuePosition}
              scheduledTrip={props.scheduledTrip}
              isStuckInPicking={isStuckInPicking(props.order, props.time)}
              time={props.time}
            />
          </HoverCard.Dropdown>
        </HoverCard>
      </OrderIndicators>
    </Box>
  );
};

const statusLabel: Record<RiderStatus, string> = {
  OfferPending: "Offer Pending",
  Available: "Available",
  AwaitingUpdate: "Awaiting Update",
  Idle: "Idle",
  OnBreak: "On Break",
};

const RiderInHub = (props: { rider: Rider; time: Date }): ReactElement => {
  return (
    <TripBox isFrozen={false}>
      <Indicator
        disabled={!(props.rider.Status === "OfferPending")}
        size={15}
        label={
          <IconWithTooltip icon={<IconHandGrab size={10} />} label={"Offer"} />
        }
        color="green"
      >
        <HoverCard position="bottom" withArrow shadow="md">
          <HoverCard.Target>
            <Hoverable>
              <Center fz="xs" h={40} mx={10}>
                {statusLabel[props.rider.Status]}
              </Center>
            </Hoverable>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Tooltip label={props.rider.ID}>
              <Flex direction={"row"} align={"flex-start"}>
                <Group
                  spacing={0}
                  style={{ justifyContent: "flex-end", width: "100%" }}
                  noWrap
                >
                  <Text sx={{ whiteSpace: "nowrap" }}>
                    {"Rider: " + getRiderPseudonym(props.rider.ID)}
                  </Text>
                  <CopyButton value={props.rider.ID} />
                  <Text
                    color="dimmed"
                    size={"xs"}
                    fz={10}
                    fw={200}
                    sx={{
                      textOverflow: "ellipsis",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {props.rider.ID}
                  </Text>
                </Group>
              </Flex>
            </Tooltip>
            <Table>
              <tbody>
                <tr>
                  <td>Rider Status</td>
                  <td>{props.rider.Status}</td>
                </tr>
                <tr>
                  <td>Time Since Last Status Change</td>
                  <td>
                    {formatDurationInMinutes(
                      props.time,
                      props.rider.StatusChange,
                    )}
                  </td>
                </tr>
                {!isEmptyDate(props.rider.LastTripInteraction) && (
                  <tr>
                    <td>Time Since Last Trip Interaction</td>
                    <td>
                      {formatDurationInMinutes(
                        props.time,
                        props.rider.LastTripInteraction,
                      )}
                    </td>
                  </tr>
                )}
                {props.rider.PlannedShifts.map((shift, i) => (
                  <Fragment key={i}>
                    <tr>
                      <td>
                        {props.rider.PlannedShifts.length === 1
                          ? "Planned Shift Start"
                          : "Shift " + (i + 1) + " Start"}
                      </td>
                      <td>
                        <Time date={shift.start} showDate={false} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {props.rider.PlannedShifts.length === 1
                          ? "Planned Shift End"
                          : "Shift " + (i + 1) + " End"}
                      </td>
                      <td>
                        <Time date={shift.end} showDate={false} />
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </HoverCard.Dropdown>
        </HoverCard>
      </Indicator>
    </TripBox>
  );
};

const sortRiders = (riders: Array<Rider>): Array<Rider> => {
  const sorted: Array<Rider> = [];
  Object.keys(statusLabel).map((status) => {
    riders
      .filter((rider) => rider.Status === status)
      .map((rider) => sorted.push(rider));
  });
  return sorted;
};

export const TripProposalSummary = (props: {
  trip: DeliveryTrip;
  isTripProposal: boolean;
  hubSlug: HubSlug;
  withheldFromPicking?: OrderIDSet;
  startTimestamp?: keyof TimestampEvents;
  doneTimestamp?: keyof TimestampEvents;
  orderLabels: OrderLabelsSelection;
  time: Date;
  vehicle?: Vehicle | undefined;
  setOpenedTripId: (tripId?: string) => void;
  pickerQueue?: PickingTask[];
  scheduledTrip?: scheduledTripProposal;
}): ReactElement => {
  const [selectedOrder, setSelectedOrder] = useHashParam(
    HashParams.SelectedOrder,
  );
  const firstDeliveryTimestamps = props.trip.FirstDelivery.Timestamps.Events;
  let tripIsFrozen: boolean = props.trip.FirstDelivery.FrozenAt != null;
  props.trip.IndirectDeliveries.forEach(
    (indirectDelivery: IndirectDelivery) => {
      tripIsFrozen = tripIsFrozen && indirectDelivery.FrozenAt != null;
    },
  );
  tripIsFrozen = tripIsFrozen && props.isTripProposal;
  return (
    <TripBox isFrozen={tripIsFrozen}>
      <TripIndicators
        offered={!!("Offer" in props.trip && props.trip.Offer)}
        locked={!!("Locked" in props.trip && props.trip.Locked)}
        claimed={
          !!firstDeliveryTimestamps.riderClaimed.Time &&
          !firstDeliveryTimestamps.enRoute.Time
        }
        vehicle={props.vehicle}
      >
        <Group position={"left"} grow={false} spacing={4}>
          {tripDeliveries(props.trip).map((o) => (
            <OrderSummary
              key={o.ID}
              order={o}
              isSelectedOrder={o.ID === selectedOrder}
              setSelectedOrder={setSelectedOrder}
              orderLabels={props.orderLabels}
              startTimestamp={props.startTimestamp}
              doneTimestamp={props.doneTimestamp}
              isWithheld={
                props.withheldFromPicking?.flat().includes(o.ID) || false
              }
              time={props.time}
              pickerQueue={props.pickerQueue}
              scheduledTrip={props.scheduledTrip}
            />
          ))}
          <Box style={{ overflow: "hidden" }}>
            <ActionIcon
              mx={-8}
              data-dd-action-name={"Trip popover button"}
              onClick={() =>
                props.setOpenedTripId(getDeliveryTripId(props.trip))
              }
            >
              <IconDotsVertical />
            </ActionIcon>
          </Box>
        </Group>
      </TripIndicators>
    </TripBox>
  );
};

const ColumnOfTrips = (props: {
  outsourced: boolean;
  hub: HubSlug;
  trips: DeliveryTrip[];
  withheldFromPicking?: OrderIDSet;
  startTimestamp?: keyof TimestampEvents;
  doneTimestamp?: keyof TimestampEvents;
  orderLabels: OrderLabelsSelection;
  time: Date;
  riders?: Rider[];
  orderIDsStringToVehicleMap: Map<string, Vehicle | undefined>;
  sx: { gridColumn: string; gridRow: string };
  setOpenedTripId: (tripId?: string) => void;
  pickerQueue?: PickingTask[];
  proposalSchedule?: scheduledTripProposal[];
}) => {
  const claimTimeWidth = 40;
  const proposalsTooltip = (
    <Text>
      <Text weight={"bold"}>In-Hub Trip Proposals</Text>
      <Box sx={frozenStyle}>
        <Text>FROZEN FOR STACKER</Text>
      </Box>
    </Text>
  );
  return (
    <Flex
      mih={80}
      gap="xs"
      direction={"column"}
      align="flex-start"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0],
        ...props.sx,
      })}
    >
      {props.outsourced ? (
        <></>
      ) : props.sx.gridColumn === "1" ? (
        <Tooltip label={proposalsTooltip}>
          <Box ml={claimTimeWidth + 6}>
            <IconBuildingWarehouse />
          </Box>
        </Tooltip>
      ) : props.sx.gridColumn === "2" ? (
        <Tooltip label="Active Trips and Riders">
          <Box>
            <IconBike />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip label="Recently Completed Trips">
          <Box>
            <IconHomeCheck />
          </Box>
        </Tooltip>
      )}
      {props.trips.map((trip, i) => {
        let vehicle: Vehicle | undefined;
        if (props.orderIDsStringToVehicleMap != undefined) {
          vehicle = props.orderIDsStringToVehicleMap.get(
            generateIDForDeliveryTrip(trip),
          );
        }
        const scheduledTrip = props.proposalSchedule?.find(
          (proposal) => proposal.order_ids.join("") === getDeliveryTripId(trip),
        );
        return (
          <Group key={i} spacing={6}>
            {scheduledTrip ? (
              <Tooltip label={"Expected Claim Time and Rider"}>
                <Stack spacing={0} w={claimTimeWidth}>
                  <Text size={"sm"} align={"end"}>
                    {Math.round(
                      (scheduledTrip?.expected_claim.getTime() -
                        props.time.getTime()) /
                        60 /
                        1000,
                    ) + "m"}
                  </Text>
                  <Text
                    color={"dimmed"}
                    size={8}
                    align={"end"}
                    sx={{
                      textOverflow: "ellipsis",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {scheduledTrip.rider_id}
                  </Text>
                </Stack>
              </Tooltip>
            ) : props.sx.gridColumn === "1" ? (
              <Space w={40} />
            ) : (
              <></>
            )}
            <TripProposalSummary
              trip={trip}
              isTripProposal={props.sx.gridColumn === "1"}
              startTimestamp={props.startTimestamp}
              withheldFromPicking={props.withheldFromPicking}
              doneTimestamp={props.doneTimestamp}
              orderLabels={props.orderLabels}
              hubSlug={props.hub}
              time={props.time}
              setOpenedTripId={props.setOpenedTripId}
              pickerQueue={props.pickerQueue}
              scheduledTrip={scheduledTrip}
              vehicle={vehicle}
            />
          </Group>
        );
      })}
      {props.trips.length > 0 && <Space h={6}></Space>}
      {props.riders &&
        !props.outsourced &&
        props.riders
          .filter(
            (rider) => rider.Status !== "Offline" && rider.Status !== "OnTrip",
          )
          .map((rider) => (
            <div key={rider.ID}>
              {props.time && <RiderInHub rider={rider} time={props.time} />}
            </div>
          ))}
    </Flex>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ),
);

type OrderLabel = {
  label: string;
  description: string;
  render: (
    order: Order,
    referenceTime: Date,
    stateColor: string,
    isFilled: boolean,
  ) => ReactNode;
};

const availableOrderLabels: Record<string, OrderLabel> = {
  order_id: {
    label: "Order Id",
    description: "Show the first 4 digits of the Order UUID.",
    render: (order) => order.ID.substring(0, 3),
  },
  order_number: {
    label: "Order Number",
    description: "Show the last 4 characters of the order number.",
    render: (order) =>
      useExtendedOrderInfo(order.ID)
        .data?.OrderNumber.slice(-4)
        .toUpperCase() || "????",
  },
  order_age: {
    label: "Order Age (minutes)",
    description: "Show the age of the order.",
    render: (order, referenceTime) =>
      `${Math.round(
        (referenceTime.getTime() -
          order.Timestamps.Events.created.Time!.getTime()) /
          1000 /
          60,
      )}m`,
  },
  due_in: {
    label: "Due in / delay (minutes)",
    description:
      "Show the time left until the PDT is used up. If the order has arrived at the customer the delivery delay. " +
      "For planned orders the duration until the start and end of its delivery window is shown. " +
      "If the start of the window has passed only the duration to or delay since the end of the window",
    render: (order: Order, referenceTime: Date): ReactNode => {
      const stateTime = referenceTime.getTime();
      const refTime = order.Timestamps.Events.arrived.Time
        ? order.Timestamps.Events.arrived.Time.getTime()
        : stateTime;
      if (!order.delivery_window) {
        return (
          Math.round(
            order.PDT -
              (refTime - order.Timestamps.Events.created.Time!.getTime()) /
                1000 /
                60,
          ) + "m"
        );
      }
      if (order.delivery_window.start.getTime() > stateTime) {
        return (
          Math.round(
            (order.delivery_window.start.getTime() - stateTime) / 1000 / 60,
          ) +
          "m-" +
          Math.round(
            (order.delivery_window.end.getTime() - stateTime) / 1000 / 60,
          ) +
          "m"
        );
      }
      return (
        Math.round(
          (order.delivery_window.end.getTime() - refTime) / 1000 / 60,
        ) + "m"
      );
    },
  },
  weight: {
    label: "Weight Estimate (kg)",
    description: "Weight of the items based on product data.",
    render: (order) =>
      formatNumberString((order.Items.TotalWeight / 1000.0).toFixed(1), "kg"),
  },
  pdt: {
    label: "PDT (minutes)",
    description: "Show the PDT that was displayed to the customer at checkout.",
    render: (order) => `${order.PDT}m`,
  },
  containers: {
    label: "Container Sizes",
    description:
      "Show the number of containers and their sizes. The available sizes are represented as a square (small) or a rectangle with aspect ratio of 2/1 (medium) or 3/1 (large).",
    render: (
      order: Order,
      referenceTime: Date,
      stateColor: string,
      isFilled: boolean,
    ) =>
      order.ContainerIDs.length === 0 ? (
        "?"
      ) : (
        <ContainerIconGroup
          containerIDs={order.ContainerIDs}
          pillColor={stateColor}
          isFilled={isFilled}
        />
      ),
  },
  arrows: {
    label: "Location Indicator",
    description: "Show the direction of the order relative to the hub.",
    render: (
      order: Order,
      referenceTime: Date,
      stateColor: string,
      isFilled: boolean,
    ) => <ArrowSVG order={order} color={isFilled ? "white" : stateColor} />,
  },
  delivery_time: {
    label: "Routed Duration Estimate (minutes)",
    description: `Show the estimated riding duration to deliver this order directly and return to the hub. Ingoring hand-over time and
       using raw estimate from the router`,
    render: (order: Delivery) => {
      return order.RoutedFromHub && order.RoutedToHub
        ? Math.round(order.RoutedFromHub! + order.RoutedToHub!) + "m"
        : "0m";
    },
  },
};

type OrderLabelName = keyof typeof availableOrderLabels;
type OrderLabelsSelection = ReadonlyArray<OrderLabelName>;

const parseOrderLabelsSelection = (
  s: string,
  orderIdentifierDisplayContext: OrderIdentifierDisplayContext,
): OrderLabelsSelection =>
  (
    s
      .split(",")
      .filter((l) => l in availableOrderLabels) as OrderLabelsSelection
  ).map((l) =>
    l === orderIdentifierDisplayContext.omitted
      ? orderIdentifierDisplayContext.selected
      : l,
  );

const renderOrderLabelsSelection = (
  orderLabels: OrderLabelsSelection,
): string => orderLabels.join(",");

const loadDefaultOrderLabelsSelection = (
  orderIdentifierDisplayContext: OrderIdentifierDisplayContext,
): OrderLabelsSelection => {
  const fromStorage = parseOrderLabelsSelection(
    localStorage.getItem("selectedLabels") || "",
    orderIdentifierDisplayContext,
  );
  if (fromStorage.length) return fromStorage;

  return [orderIdentifierDisplayContext.selected];
};

const setDefaultOrderLabelsSelection = (
  orderLabels: OrderLabelsSelection,
): void =>
  localStorage.setItem(
    "selectedLabels",
    renderOrderLabelsSelection(orderLabels),
  );

export const useOrderLabelsSelection = (): [
  OrderLabelsSelection,
  (selection: OrderLabelsSelection) => void,
] => {
  const orderIdentifierDisplayContext = useIdOrNumberContext();

  const loadOrderLabelsSelection = useCallback(
    (hashParam: string | null): OrderLabelsSelection => {
      const fromHashParam = parseOrderLabelsSelection(
        hashParam || "",
        orderIdentifierDisplayContext,
      );
      if (fromHashParam.length) return fromHashParam;

      return loadDefaultOrderLabelsSelection(orderIdentifierDisplayContext);
    },
    [orderIdentifierDisplayContext.selected],
  );

  const [orderLabelsSelection, setOrderLabelsSelection] = useHashParam(
    HashParams.OrderLabels,
    loadOrderLabelsSelection,
    renderOrderLabelsSelection,
  );

  const [explicitlyEmpty, setExplicitlyEmpty] = useState(false);

  return [
    explicitlyEmpty ? [] : orderLabelsSelection,
    (labels) => {
      setExplicitlyEmpty(labels.length === 0);
      if (labels.length) setOrderLabelsSelection(labels);
    },
  ];
};

export const TripActivityColumns = (props: {
  outsourced: boolean;
  state: HubState;
  withheldFromPicking: OrderIDSet;
  hub: string;
  orderLabels: OrderLabelsSelection;
  setOpenedTripId: (tripId?: string) => void;
  pickerQueue?: PickingTask[];
  proposalSchedule?: scheduledTripProposal[];
}) => {
  const row = props.outsourced ? "3" : "1";
  const tripIdToVehicleMap = new Map(
    props.state.Trips.map((trip) => [
      generateIDForDeliveryTrip(trip),
      getVehiclForTrip(trip, props.state.Riders, props.state.Vehicles),
    ]),
  );
  return (
    <Fragment>
      <ColumnOfTrips
        outsourced={props.outsourced}
        hub={props.hub}
        trips={
          props.outsourced
            ? props.state.OutsourcedTrips.filter(
                (t) => !t.FirstDelivery.Timestamps.Events.riderClaimed.Time,
              )
            : props.state.TripProposals
        }
        withheldFromPicking={props.withheldFromPicking}
        time={props.state.Timestamp}
        startTimestamp={"pickerAccepted"}
        doneTimestamp={"pickingComplete"}
        orderLabels={props.orderLabels}
        sx={{
          gridColumn: "1",
          gridRow: row,
        }}
        setOpenedTripId={props.setOpenedTripId}
        pickerQueue={props.pickerQueue}
        proposalSchedule={props.proposalSchedule}
        orderIDsStringToVehicleMap={tripIdToVehicleMap}
      />
      <ColumnOfTrips
        outsourced={props.outsourced}
        hub={props.hub}
        trips={
          props.outsourced
            ? props.state.OutsourcedTrips.filter(
                (t) =>
                  t.FirstDelivery.Timestamps.Events.riderClaimed.Time &&
                  !t.FirstDelivery.Timestamps.Events.delivered.Time,
              )
            : props.state.Trips.filter((t) => isEmptyDate(t.CompletedAt))
        }
        startTimestamp={"arrived"}
        doneTimestamp={"delivered"}
        orderLabels={props.orderLabels}
        time={props.state.Timestamp}
        riders={sortRiders(Object.values(props.state.Riders))}
        sx={{ gridColumn: "2", gridRow: row }}
        setOpenedTripId={props.setOpenedTripId}
        orderIDsStringToVehicleMap={tripIdToVehicleMap}
      />
      <ColumnOfTrips
        hub={props.hub}
        outsourced={props.outsourced}
        trips={
          props.outsourced
            ? props.state.OutsourcedTrips.filter(
                (t) =>
                  !isEmptyDate(t.CompletedAt) &&
                  props.state.Timestamp.getTime() - t.CompletedAt.getTime() <
                    15 * 60 * 1000,
              )
            : props.state.Trips.filter(
                (t) =>
                  !isEmptyDate(t.CompletedAt) &&
                  props.state.Timestamp.getTime() - t.CompletedAt.getTime() <
                    15 * 60 * 1000,
              )
        }
        orderLabels={props.orderLabels}
        time={props.state.Timestamp}
        sx={{ gridColumn: "3", gridRow: row }}
        setOpenedTripId={props.setOpenedTripId}
        orderIDsStringToVehicleMap={tripIdToVehicleMap}
      />
    </Fragment>
  );
};

export const NonTripOrders = (props: {
  icon: ReactElement;
  label: string;
  orders: Order[];
  orderLabels: OrderLabelsSelection;
  time: Date;
  withheldFromPicking: OrderIDSet;
  pickerQueue?: PickingTask[];
}) => {
  const [selectedOrder, setSelectedOrder] = useHashParam(
    HashParams.SelectedOrder,
  );

  return (
    <Flex hidden={!props.orders.length} mr="lg">
      <Tooltip label={props.label}>
        <Flex align={"center"} mr="xs">
          {props.icon}
        </Flex>
      </Tooltip>
      {props.orders.map((o) => (
        <OrderSummary
          key={o.ID}
          order={o}
          isSelectedOrder={o.ID === selectedOrder}
          setSelectedOrder={setSelectedOrder}
          orderLabels={props.orderLabels}
          time={props.time}
          startTimestamp={"pickerAccepted"}
          doneTimestamp={"pickingComplete"}
          isWithheld={props.withheldFromPicking?.flat().includes(o.ID) || false}
          pickerQueue={props.pickerQueue}
        />
      ))}
    </Flex>
  );
};

export type SelectionJSX = ReactNode[];

export const HubTripsAndProposalsOverview = (props: {
  state: HubState;
  withheldFromPicking: OrderIDSet;
  slug: string;
  setOpenedTripId: (tripId?: string) => void;
  proposalSchedule?: scheduledTripProposal[];
}) => {
  const theme = useMantineTheme();
  const [mapOpened, setMapOpened] = useState(false);
  const orderIdentifierDisplayContext = useIdOrNumberContext();
  const [selectedOrderLabels, setSelectedOrderLabels] =
    useOrderLabelsSelection();

  const pickerQueueResource = usePickerQueue({
    id: props.state.ID,
  });

  return (
    <Stack>
      {mapOpened && (
        <HubAreaView
          state={props.state}
          withheldFromPicking={props.withheldFromPicking}
          navigation={false}
        />
      )}
      <Flex align={"flex-start"} direction={"row"} gap={"md"}>
        <MultiSelect
          data={Object.keys(availableOrderLabels)
            .filter((name) => name !== orderIdentifierDisplayContext.omitted)
            .map((name) => ({
              label: availableOrderLabels[name].label,
              value: name,
              description: availableOrderLabels[name].description,
            }))}
          value={selectedOrderLabels as string[]}
          onChange={(newSelection) =>
            setSelectedOrderLabels(newSelection as OrderLabelsSelection)
          }
          itemComponent={SelectItem}
          rightSection={
            <>
              {selectedOrderLabels.length > 0 ? (
                <IconX
                  color={theme.colors.gray[6]}
                  onClick={() => {
                    setSelectedOrderLabels([]);
                  }}
                />
              ) : (
                <IconSelector color={theme.colors.gray[6]} />
              )}
            </>
          }
          sx={{ width: "100%" }}
          data-dd-action-name={"Order label selection"}
          searchable
        />
        <Tooltip label={"Save Current Selection"}>
          <Button
            variant="outline"
            onClick={() => setDefaultOrderLabelsSelection(selectedOrderLabels)}
            data-dd-action-name={"Save order label selection button"}
          >
            <Text>Save</Text>
          </Button>
        </Tooltip>
        <Button
          variant="outline"
          color={theme.colors.gray[5]}
          onClick={() =>
            setSelectedOrderLabels(
              loadDefaultOrderLabelsSelection(orderIdentifierDisplayContext),
            )
          }
          data-dd-action-name={"Restore order label selection button"}
        >
          <Text>Restore</Text>
        </Button>
        <Button
          variant="outline"
          color={theme.colors.gray[5]}
          onClick={() => setMapOpened(!mapOpened)}
          data-dd-action-name={"Show Map in Trips and Proposals"}
        >
          <Text>{mapOpened ? "Hide Map" : "Show Map"}</Text>
        </Button>
      </Flex>
      <Box
        hidden={
          !(
            props.state.InStorePaymentOrders.length ||
            props.state.ExternalDeliveryOrders.length ||
            props.state.ClickAndCollectOrders.length
          )
        }
      >
        <Flex>
          {nonTripOrders(props.state).map((order, i) => (
            <NonTripOrders
              key={i}
              icon={order.icon}
              label={order.group}
              orders={order.collection}
              orderLabels={selectedOrderLabels}
              time={props.state.Timestamp}
              withheldFromPicking={props.withheldFromPicking}
              pickerQueue={pickerQueueResource.data}
            />
          ))}
        </Flex>
        <Divider m="xs" />
      </Box>
      <Flex
        direction={"column"}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(240px, max-content))",
          columnGap: "20px",
        }}
      >
        <TripActivityColumns
          outsourced={false}
          state={props.state}
          hub={props.slug}
          withheldFromPicking={props.withheldFromPicking}
          orderLabels={selectedOrderLabels}
          setOpenedTripId={props.setOpenedTripId}
          pickerQueue={pickerQueueResource.data}
          proposalSchedule={props.proposalSchedule}
        />
        {props.state.OutsourcedTrips.filter(
          (t) =>
            isEmptyDate(t.CompletedAt) ||
            props.state.Timestamp.getTime() - t.CompletedAt.getTime() <
              15 * 60 * 1000,
        ).length > 0 && (
          <Box sx={{ gridColumn: "1/4", gridRow: "2" }}>
            <Divider m="xs" />
            <Text weight={"bold"}>Outsourced Trips:</Text>
          </Box>
        )}
        <TripActivityColumns
          outsourced={true}
          state={props.state}
          hub={props.slug}
          withheldFromPicking={props.withheldFromPicking}
          orderLabels={selectedOrderLabels}
          setOpenedTripId={props.setOpenedTripId}
          pickerQueue={pickerQueueResource.data}
          proposalSchedule={props.proposalSchedule}
        />
      </Flex>
    </Stack>
  );
};

export type DeliveryTrip = Trip | TripProposal | OutsourcedTrip;

type NonTripOrderData = {
  group: string;
  collection: Order[];
  icon: ReactElement;
};

function getVehiclForTrip(
  trip: Trip,
  ridersMap: Record<RiderID, Rider>,
  vehiclesMap: Record<VehicleID, Vehicle>,
): Vehicle | undefined {
  if (trip.RiderID != undefined) {
    if (ridersMap[trip.RiderID] != undefined) {
      const rider = ridersMap[trip.RiderID];
      if (rider.VehicleID != undefined) {
        return vehiclesMap[rider.VehicleID];
      }
    }
  }
}

function generateIDForDeliveryTrip(deliveryTrip: DeliveryTrip): string {
  let id = "";
  id += deliveryTrip.FirstDelivery.ID;
  for (const indirectDelivery of deliveryTrip.IndirectDeliveries) {
    id += indirectDelivery.ID;
  }
  return id;
}

const nonTripOrders = (state: HubState): NonTripOrderData[] => [
  {
    group: "In-Store Orders Not Yet Packed",
    collection: state.InStorePaymentOrders,
    icon: <IconBuildingStore />,
  },
  {
    group: "External Orders Not Yet Packed",
    collection: state.ExternalDeliveryOrders,
    icon: <IconPaperBag />,
  },
  {
    group: "Click & Collect Orders Not Yet Packed",
    collection: state.ClickAndCollectOrders,
    icon: <IconClick />,
  },
];
