import {
  Anchor,
  Badge,
  Box,
  DefaultMantineColor,
  Divider,
  Grid,
  Group,
  HoverCard,
  Image,
  Indicator,
  List,
  MantineTheme,
  Paper,
  Stack,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import dateFormat from "dateformat";
import { ReactNode, useEffect, useMemo, useState } from "react";
import * as vega from "vega";
import { unaggregateDomainHasNoEffectForRawField } from "vega-lite/build/src/log/message";
import vegaTooltipModule from "vega-tooltip";
import { z } from "zod";
import { ApiResource, useOrder } from "../api";
import { useIdOrNumberContext } from "../app";
import { Collapsible } from "../components/collapsible";
import { CommandData, CommandName } from "../components/command";
import { Container } from "../components/container";
import { Content, Section } from "../components/content";
import { CopyButton } from "../components/copy";
import { DateTime, Time } from "../components/date";
import { JsonTree } from "../components/json";
import { ExternalLink } from "../components/link";
import { GeoJsonMap } from "../components/map";
import { isOlderThanADay } from "../components/state-hovercard";
import { useConfig } from "../config";
import * as geoJson from "../geojson";
import { getObjectDepth, safeJsonParse } from "../methods";
import { Link, OrderLink, routes } from "../nav";
import { FlinkPink } from "../themes";
import {
  CombinedOrder,
  DeliveryProgressEvent,
  DeliveryProgressEvents,
  OrderID,
  OrderNumber,
  OrderSummary,
  RawCTOrder,
  TimestampEvents,
} from "../types";
import {
  constConformingTo,
  formatDeliveryWindow,
  formatDurationInMinutes,
  formatNumber,
  formatNumberString,
  getLatestEvent,
  orderedTimestampEvents,
  valueOrUndefined,
} from "../utils";
import { HashParams } from "./hubs/hubs";
import {
  DISABLED_TEMP_INDICATOR_COLOR,
  getTemperatureIcon,
  NO_ICON,
} from "./hubs/indicators";

const summaryColumns: Record<string, (summary: OrderSummary) => ReactNode> = {
  "Order ID": (summary) => (
    <Link route={routes.orders.sub.byId} params={{ idOrNumber: summary.ID }}>
      {summary.ID}
    </Link>
  ),
  Hub: (summary) => (
    <Link route={routes.hubs.sub.bySlug} params={{ slug: summary.Hub }}>
      {summary.Hub}
    </Link>
  ),
  "Created At": (summary) => <DateTime date={summary.CreatedAt} />,
};

export const OrdersView = () => {
  return (
    <Content title="Recent Orders">
      <ApiResource
        accessor={{ path: "/recent_orders", schema: z.array(OrderSummary) }}
      >
        {(recentOrders) => (
          <Table>
            <thead>
              <tr>
                {Object.keys(summaryColumns).map((label) => (
                  <th key={label}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {recentOrders.map((summary) => (
                <tr key={summary.ID}>
                  {Object.values(summaryColumns).map((column, i) => (
                    <td key={i}>{column(summary)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ApiResource>
    </Content>
  );
};

const OldOrderBadge = () => {
  const theme = useMantineTheme();
  return (
    <Text
      sx={{
        backgroundColor: theme.colors.yellow[5],
        position: "fixed",
        color: "white",
        top: "5px",
        right: "15px",
        display: "flex",
        borderRadius: "30px",
        justifyContent: "center",
        fontSize: "30px",
        userSelect: "none",
        zIndex: 10,
        pointerEvents: "none",
        alignItems: "center",
        paddingLeft: "15px",
        paddingRight: "10px",
        fontWeight: "bold",
      }}
    >
      Warning: older than 24 hours
    </Text>
  );
};

export const OrderView = (params: { idOrNumber: OrderID | OrderNumber }) => {
  const config = useConfig();
  const [currentTime] = useState(new Date());

  return (
    <ApiResource accessor={() => useOrder({ idOrNumber: params.idOrNumber })}>
      {(data) => {
        const orderIdentifierDisplayContext = useIdOrNumberContext();
        const orderId = data.Dispatching.ID;
        const orderNumber = data.Commercetools.orderNumber;
        const orderNumberUpperCase = orderNumber.toUpperCase();
        const hubSlug = data.Dispatching.Hub;
        const hub = config.hubs.get(hubSlug);
        const createdAt = data.Dispatching.Timestamps.Events.created.Time!;
        const etaAtCheckout = new Date(
          createdAt.getTime() + data.Dispatching.ETAAtCheckout * 60_000,
        );

        const orderSummary: [string, ReactNode | (() => ReactNode) | null][] = [
          [
            "ID",
            <Group spacing={0}>
              {data.Dispatching.ID}
              <CopyButton value={orderId} />
            </Group>,
          ],
          [
            "Number",
            <Group spacing={0}>
              {orderNumberUpperCase}
              <CopyButton value={orderNumberUpperCase} />
            </Group>,
          ],
          [
            "Hub",
            <Group spacing={0}>
              <Link route={routes.hubs.sub.bySlug} params={{ slug: hubSlug }}>
                {" "}
                {hubSlug}
              </Link>
              <CopyButton value={hubSlug} />
            </Group>,
          ],
          [
            "Type",
            () => {
              switch (data.Dispatching.DeliveryType) {
                case "FlinkDelivery":
                  return <Badge color="pink">Delivered by Flink</Badge>;
                case "ClickAndCollect":
                  return <Badge color="indigo">Click & Collect</Badge>;
                case "InStorePayment":
                  return <Badge color="green">In-Store Payment</Badge>;
                case "ExternalDelivery":
                  return <Badge color="yellow">Delivered by External</Badge>;
                case "FlinkASAPDelivery":
                  return <Badge color="pink">Delivered by Flink (ASAP)</Badge>;
                case "FlinkPlannedDelivery":
                  return (
                    <Badge color="pink">
                      Delivered by Flink (Planned Delivery)
                    </Badge>
                  );
                default:
                  return (
                    <Badge color="gray">{data.Dispatching.DeliveryType}</Badge>
                  );
              }
            },
          ],
          ["Created At", <DateTime date={createdAt} />],
          [
            "Delivery Window (Timeslot)",
            data.Dispatching.delivery_window
              ? formatDeliveryWindow(data.Dispatching.delivery_window)
              : null,
          ],
          ["Last Event", getLatestEvent(data.Dispatching.Timestamps.Events)],
          [
            "Weight Estimate",
            <Group spacing={0}>
              {formatNumberString(
                (data.Dispatching.Items.TotalWeight / 1000.0).toFixed(1),
                "kg",
              )}
              <CopyButton
                value={(data.Dispatching.Items.TotalWeight / 1000.0).toFixed(1)}
              />
            </Group>,
          ],
          [
            "Containers",
            <>
              {data.Dispatching.ContainerIDs.map((containerID) => (
                <Container key={containerID} containerID={containerID} />
              ))}
            </>,
          ],
        ];

        const links: [string, string | (() => string)][] = [
          [
            "Datadog",
            `https://app.datadoghq.eu/logs?query=env%3A${
              config.env
            }%20%28%40flink.order.id%3A${orderId}
            %20%7C%7C%20%40flink.order.number%3A${orderNumber}%29%20&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&from_ts=${
              createdAt.getTime() - 600000
            }&to_ts=${createdAt.getTime() + 3600000}&live=false`,
          ],
          [
            "Commercetools",
            `https://mc.europe-west1.gcp.commercetools.com/${
              config.env === "prod" ? "flink-production" : "flink-staging"
            }/orders/${orderId}/general`,
          ],
          [
            "Google Maps",
            () => {
              const params = {
                api: 1,
                travelmode: "bicycling",
                origin: `${hub?.default_location?.latitude},${hub?.default_location?.longitude}`,
                destination: `${data.Dispatching.DeliveryCoords.latitude},${data.Dispatching.DeliveryCoords.longitude}`,
              };
              return `https://www.google.com/maps/dir/?${Object.entries(params)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&")}`;
            },
          ],
        ];

        return (
          <Content
            title={`Order ${
              orderIdentifierDisplayContext.selected === "order_number"
                ? orderNumberUpperCase
                : orderId
            }`}
          >
            {isOlderThanADay(createdAt) && <OldOrderBadge />}
            <Grid grow gutter="xl" columns={12}>
              <Grid.Col span={10}>
                <Table highlightOnHover fontSize="md">
                  <tbody>
                    {orderSummary
                      .filter(([, value]) => value != null)
                      .map(([label, value]) => (
                        <tr key={label}>
                          <td>{label}</td>
                          <td>
                            {typeof value === "function" ? value() : value}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Section title="Items">
                  <Paper style={{ padding: 16 }} radius="md">
                    <Group spacing="xs" data-dd-action-name={"Order item"}>
                      {data.Commercetools.lineItems.flatMap((item) => {
                        const title = item.variant.attributes.find(
                          (attr) => attr.name === "erpName",
                        )?.value;

                        const image = item.variant.images[0];
                        const previewUrl =
                          image &&
                          productImageUrl(
                            image.url,
                            "c_fill,g_auto,h_300,w_300",
                          );

                        function attribute<T>(name: string): T | undefined {
                          return item.variant.attributes.find(
                            (a) => a.name === name,
                          )?.value as T | undefined;
                        }
                        const grossWeight = attribute<number>("grossWeight");
                        const productUnit = attribute<number>("productUnit");
                        const uom = attribute<string>("uom");
                        const sku = item.variant.sku;
                        let [tempIcon, iconColour] = NO_ICON;
                        if (
                          data.ExtendedOrderInfo &&
                          data.ExtendedOrderInfo.LineItems[sku] &&
                          data.ExtendedOrderInfo.LineItems[sku]
                            .TemperatureCategories
                        ) {
                          [tempIcon, iconColour] = getTemperatureIcon(
                            data.ExtendedOrderInfo.LineItems[sku]
                              .TemperatureCategories,
                          );
                        }
                        return Array(data.Dispatching.Items.BySKU[sku])
                          .fill(0)
                          .map((_, i) => (
                            <Group position="center" key={`${item.id}-${i}`}>
                              <HoverCard width={320} shadow="md">
                                <HoverCard.Target>
                                  <div>
                                    <Indicator
                                      disabled={
                                        iconColour ==
                                        DISABLED_TEMP_INDICATOR_COLOR
                                      }
                                      size={20}
                                      inline
                                      label={tempIcon}
                                      position="top-end"
                                      color={iconColour}
                                    >
                                      <Image
                                        width={72}
                                        src={previewUrl}
                                        withPlaceholder
                                      />
                                    </Indicator>
                                  </div>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                  <Text weight="bold">{title}</Text>
                                  <Text>SKU: {item.variant.sku}</Text>
                                  <Text hidden={grossWeight === undefined}>
                                    Gross weight:{" "}
                                    {formatNumber(grossWeight, "g")}
                                  </Text>
                                  <Text
                                    hidden={
                                      productUnit === undefined &&
                                      uom === undefined
                                    }
                                  >
                                    Sizing Information:{" "}
                                    {formatNumber(productUnit, uom)}
                                  </Text>
                                  <Indicator
                                    disabled={
                                      iconColour ==
                                      DISABLED_TEMP_INDICATOR_COLOR
                                    }
                                    size={20}
                                    inline
                                    label={tempIcon}
                                    position="top-end"
                                    color={iconColour}
                                  >
                                    <Image src={previewUrl} withPlaceholder />
                                  </Indicator>
                                </HoverCard.Dropdown>
                              </HoverCard>
                            </Group>
                          ));
                      })}
                    </Group>
                  </Paper>
                </Section>
                <Section title="Delivery">
                  Estimated Delivery: <Time date={etaAtCheckout} />
                  <DeliveryTimeline data={data} currentTime={currentTime} />
                  <Collapsible
                    label="Timestamps"
                    data-dd-action-name={"Order timestamps collapsible"}
                  >
                    <Grid>
                      <Grid.Col span={5}>
                        <Title order={4}>
                          Dispatching's Canonical Timestamps
                        </Title>
                        <Table>
                          <tbody>
                            {Object.entries(orderedTimestampEvents)
                              .map(
                                (entry) =>
                                  entry as [keyof TimestampEvents, string],
                              )
                              .map(([key, label]) => {
                                const timestamp =
                                  data.Dispatching.Timestamps.Events[key];

                                return (
                                  <tr key={key}>
                                    <td>{label}</td>
                                    <td>
                                      {timestamp.Time && (
                                        <DateTime date={timestamp.Time} />
                                      )}
                                      {timestamp.Inferred && " (inferred)"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </Grid.Col>
                      <Grid.Col span={7}>
                        <Title order={4}>Commercetools</Title>
                        <Table>
                          <tbody>
                            {extractStateChanges(data.Commercetools!).map(
                              (change) => {
                                return (
                                  <tr
                                    key={`${change.fromState}->${change.toState}`}
                                  >
                                    <td>
                                      <Text italic>
                                        {change.fromState || "*"}
                                      </Text>
                                    </td>
                                    <td>→</td>
                                    <td>
                                      <Text italic>{change.toState}</Text>
                                    </td>
                                    <td>
                                      <DateTime date={change.time} />
                                    </td>
                                  </tr>
                                );
                              },
                            )}
                          </tbody>
                        </Table>
                      </Grid.Col>
                    </Grid>
                  </Collapsible>
                </Section>
              </Grid.Col>
              <Grid.Col span={2}>
                <GeoJsonMap
                  height={400}
                  data={geoJson.collection(
                    data.Dispatching.DeliveryCoords.latitude &&
                      data.Dispatching.DeliveryCoords.longitude
                      ? geoJson.point(data.Dispatching.DeliveryCoords, {
                          orderId,
                        })
                      : undefined,
                    hub?.default_location &&
                      geoJson.point(hub?.default_location, {
                        hub: hubSlug,
                        color: FlinkPink,
                      }),
                    ...geoJson.polygons(hub?.turfs || []),
                  )}
                  data-dd-action-name={"Order map"}
                />
                <Section title="Links">
                  <List>
                    {links.map(([label, url]) => (
                      <List.Item key={label}>
                        <ExternalLink
                          url={typeof url === "function" ? url() : url}
                          data-dd-action-name={label + " link"}
                        >
                          {label}
                        </ExternalLink>
                      </List.Item>
                    ))}
                  </List>
                </Section>
              </Grid.Col>
              <Grid.Col>
                <Section title="Trip">
                  {data.Dispatching.TrackingInformation.ID && (
                    <Box style={{ marginBottom: 24 }}>
                      ID: {data.Dispatching.TrackingInformation.ID}
                    </Box>
                  )}
                  <List type="ordered">
                    {data.Dispatching.StackedOrderIDs.map((id) => (
                      <List.Item key={id}>
                        <Group>
                          <OrderLink orderId={id} />
                          {id === orderId && <Badge>self</Badge>}
                        </Group>
                      </List.Item>
                    ))}
                  </List>
                </Section>
                <Section title="Command History">
                  <Table width={100}>
                    <thead>
                      <tr>
                        <th>Timestamp</th>
                        <th>ETA</th>
                        <th>Hub State Rev.</th>
                        <th>Command</th>
                        <th>Command Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.OrderHistory.map((historicStateSummary) => {
                        const estTripDuration = Object.values(
                          historicStateSummary.estimates,
                        ).reduce((total, duration) => {
                          total += duration;
                          return total;
                        }, 0);
                        const summary =
                          historicStateSummary.state_history_summary;
                        const vAlignTop =
                          Object.keys(summary.CommandData).filter(
                            (key) => key !== "Timestamp",
                          ).length > 1;
                        return (
                          <tr
                            key={summary.ID}
                            style={{
                              ...(vAlignTop && {
                                verticalAlign: "top",
                                paddingTop: "14px",
                              }),
                            }}
                          >
                            <td>
                              <DateTime date={summary.Timestamp} />
                            </td>
                            <td>{estTripDuration.toFixed(0)} min</td>
                            <td>
                              <Anchor
                                href={routes.hubs.sub.searchHubStateId.link(
                                  {
                                    stateId: summary.ID,
                                  },
                                  { [HashParams.SelectedOrder]: orderId },
                                )}
                                data-dd-action-name={"State link"}
                              >
                                {summary.Revision}
                              </Anchor>
                            </td>
                            <td>
                              <CommandName name={summary.CommandName} />
                            </td>
                            <td style={{ paddingLeft: 0 }}>
                              <CommandData data={summary.CommandData} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Section>
                <Section title="Others">
                  <Collapsible
                    label="Commercetools Custom Fields"
                    data-dd-action-name={
                      "Custom commercetools fields collapsible"
                    }
                  >
                    <>
                      <Divider />
                      <Grid columns={3} m={0}>
                        <Grid.Col span={1} p={4}>
                          <Text>deliveryTierID</Text>
                        </Grid.Col>
                        <Grid.Col span={2} p={4}>
                          {data.Commercetools.custom.fields.deliveryTierID}
                        </Grid.Col>
                      </Grid>
                      <Divider />
                      {Object.entries(data.Commercetools.custom.fields)
                        .filter(
                          ([k]) =>
                            k !== "stateChanges" && k !== "deliveryTierID",
                        )
                        .map(([k, v]) => {
                          const value = safeJsonParse(v) || v;
                          if (getObjectDepth(value) >= 2) {
                            return (
                              <div key={k}>
                                <Grid m={0}>
                                  <Grid.Col p={0}>
                                    <Stack>
                                      <Collapsible label={k} inTable={true}>
                                        <Divider variant={"dashed"} />
                                        {renderCTField(value, 0)}
                                      </Collapsible>
                                    </Stack>
                                  </Grid.Col>
                                </Grid>
                                <Divider />
                              </div>
                            );
                          } else {
                            return (
                              <div key={k}>
                                <Grid columns={3} m={0}>
                                  <Grid.Col span={1} p={0}>
                                    <Text p={4}>{k}</Text>
                                  </Grid.Col>
                                  <Grid.Col span={2} p={0}>
                                    {renderCTField(value, 0)}
                                  </Grid.Col>
                                </Grid>
                                <Divider />
                              </div>
                            );
                          }
                        })}
                    </>
                  </Collapsible>
                  <Collapsible
                    label="Commercetools Raw Data"
                    data-dd-action-name={"Raw commercetools data collapsible"}
                  >
                    <JsonTree data={data.Commercetools} />
                  </Collapsible>
                </Section>
              </Grid.Col>
            </Grid>
          </Content>
        );
      }}
    </ApiResource>
  );
};

const productImageUrl = (image: string, transformations?: string): string => {
  const match = image.match(/.*\/flink-([^\/]+)\/(.*)$/);
  if (!match) {
    return "";
  }

  const env = match[1];
  const filename = match[2];
  const transformationsPath = transformations ? `${transformations}/` : "";
  return `https://res.cloudinary.com/goflink/image/upload/${transformationsPath}/${env}/${filename}`;
};

const DeliveryTimeline = (props: {
  data: CombinedOrder;
  currentTime: Date;
}) => {
  const { ref, width } = useElementSize<HTMLDivElement>();
  const theme = useMantineTheme();
  const spec = useMemo(() => {
    return vega.parse(
      deliveryTimelineSpec(
        props.data,
        props.currentTime,
        width * 0.8,
        timelineColors(theme),
      ),
    );
  }, [props.data, props.currentTime, width, theme]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const view = new vega.View(spec, {
      renderer: "svg",
      container: ref.current,
      hover: true,
    });
    vegaTooltipModule(view);
    view.runAsync();

    return () => {
      view.finalize();
    };
  }, [ref.current, spec]);

  return (
    <>
      <div
        ref={ref}
        style={{ marginTop: 20, marginBottom: 20 }}
        data-dd-action-name={"Order timeline"}
      ></div>
    </>
  );
};

const deliveryTimelineSpec = (
  data: CombinedOrder,
  currentTime: Date,
  width: number,
  colors: TimelineColors,
): vega.Spec => {
  const order = data.Commercetools;
  const deliveryProgress = data.DeliveryProgress!; //TODO: handle when it is not there

  const stateChanges = extractStateChanges(order);

  const hasBeenDelivered = stateChanges.some(
    (e) => e.toState === "order-delivered",
  );

  const rawPhaseEstimatesAtCheckout = order.custom.fields
    .deliveryTimeEstDetailsAtCheckout
    ? JSON.parse(order.custom.fields.deliveryTimeEstDetailsAtCheckout)
        .phase_estimates
    : [];
  var phaseEstimateAtCheckoutTime = new Date(order.createdAt);
  const phaseEstimatesAtCheckout = [
    ...new Set([
      ...Object.keys(orderFulfillmentPhases),
      ...Object.keys(rawPhaseEstimatesAtCheckout).map((e) =>
        e.replace(/_minutes$/, ""),
      ),
    ]),
  ].map((key: string): PhaseVis => {
    const checkoutTimeMinutes = rawPhaseEstimatesAtCheckout[key + "_minutes"]
      ? rawPhaseEstimatesAtCheckout[key + "_minutes"]
      : 0;
    const end = new Date(
      phaseEstimateAtCheckoutTime.getTime() + checkoutTimeMinutes * 60000,
    );
    const phase = valueOrUndefined(orderFulfillmentPhases, key);
    const value = phaseVis({
      phaseLine: "ETA at checkout",
      type: "estimate",
      name: phase?.name,
      start: phaseEstimateAtCheckoutTime,
      end,
      color: colors.forPhase(key).estimateColor,
      height: 0.5,
    });
    phaseEstimateAtCheckoutTime = end;
    return value;
  });

  const progressEvents = Object.entries(deliveryProgress.events) as [
    DeliveryProgressEventType,
    DeliveryProgressEvent,
  ][];
  const deliveryProgressPhases = progressEvents.flatMap(
    ([type, event], i): PhaseVis[] => {
      const phaseKey = valueOrUndefined(userPhaseByEndEvent, type);
      if (phaseKey === undefined) return [];
      const phase = orderFulfillmentPhases[phaseKey];
      const phaseColors = colors.forPhase(phaseKey);
      const previousEvent = progressEvents[i - 1][1];
      return event.status === "OCCURRED"
        ? [
            phaseVis({
              phaseLine: "user-visible progress/ETA",
              type: "actual",
              name: phase.name,
              start: previousEvent.time,
              end: event.time,
              color: phaseColors.completeColor,
              height: 1,
            }),
          ]
        : previousEvent.status === "OCCURRED"
        ? [
            phaseVis({
              phaseLine: "user-visible progress/ETA",
              type: "estimate",
              name: phase.name,
              start: previousEvent.time,
              end: event.time,
              color: phaseColors.estimateColor,
              height: 0.5,
            }),
            phaseVis({
              phaseLine: "user-visible progress/ETA",
              type: "actual",
              name: phase.name,
              start: previousEvent.time,
              end: currentTime,
              color: phaseColors.completeColor,
              height: 1,
            }),
          ]
        : [
            phaseVis({
              phaseLine: "user-visible progress/ETA",
              type: "estimate",
              name: phase.name,
              start: previousEvent.time,
              end: event.time,
              color: phaseColors.estimateColor,
              height: 0.5,
            }),
          ];
    },
  );

  const orderEvents = [
    ...stateChanges.map((stateChange) => ({
      color: colors.label,
      name: stateChange.toState,
      when: stateChange.time,
    })),
    ...(hasBeenDelivered
      ? []
      : [
          {
            color: colors.currentTime,
            name: "retrieved",
            when: currentTime,
          },
        ]),
  ];

  const range = [
    ...orderEvents.map((e) => e.when),
    ...phaseEstimatesAtCheckout.flatMap((phase) => [phase.start, phase.end]),
    ...deliveryProgressPhases.flatMap((phase) => [phase.start, phase.end]),
    ...(hasBeenDelivered ? [] : [currentTime]),
    deliveryProgress.promised_delivery_time,
  ].map((when) => ({ when }));

  return {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: "Timeline of things.",
    width: width,
    height: 50,
    padding: 0,
    data: [
      {
        name: "range",
        format: {
          type: "json",
          parse: {
            when: "date",
          },
        },
        values: range,
      },
      {
        name: "phases",
        format: { type: "json", parse: { from: "date", to: "date" } },
        values: [
          phaseVis({
            phaseLine: "pre-checkout PDT",
            type: "estimate",
            start: order.createdAt,
            end: deliveryProgress.promised_delivery_time,
            color: colors.preCheckoutPDT,
            height: 0.5,
          }),
          ...phaseEstimatesAtCheckout,
          ...deliveryProgressPhases,
        ],
      },
      {
        name: "events",
        format: { type: "json", parse: { when: "date" } },
        values: orderEvents,
      },
    ],
    scales: [
      {
        name: "yscale",
        type: "band",
        range: [
          0,
          {
            signal: "height",
          },
        ],
        padding: 0.2,
        domain: {
          data: "phases",
          field: "phaseLine",
        },
      },
      {
        name: "xscale",
        type: "time",
        range: "width",
        round: true,
        domain: {
          data: "range",
          fields: ["when"],
        },
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "xscale",
        format: "%H:%M:%S",
        labelAngle: -30,
        labelAlign: "right",
        labelColor: colors.label,
      },
    ],
    marks: [
      {
        type: "text",
        from: {
          data: "phases",
        },
        encode: {
          enter: {
            align: {
              value: "right",
            },
            baseline: {
              value: "middle",
            },
            y: {
              scale: "yscale",
              field: "phaseLine",
              band: 0.5,
            },
            // height: {
            //   scale: "yscale",
            //   band: 1,
            // },
            // yc: {
            //   scale: "yscale",
            //   field: "phaseLine",
            // },
            x: {
              offset: -5,
            },
            fill: {
              value: colors.label,
            },
            text: {
              field: "phaseLine",
            },
            fontSize: {
              value: 10,
            },
          },
        },
      },
      {
        type: "rect",
        from: {
          data: "phases",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "start",
            },
            x2: {
              scale: "xscale",
              field: "end",
            },
            yc: {
              scale: "yscale",
              field: "phaseLine",
              band: 0.5,
            },
            height: {
              scale: "yscale",
              band: 1,
              mult: {
                field: "height",
              },
            },
            tooltip: {
              field: "tooltip",
            },
            fill: {
              field: "color",
            },
          },
        },
      },
      {
        type: "text",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "when",
              offset: 2,
            },
            y: {
              value: -2,
            },
            angle: {
              value: -30,
            },
            fill: {
              field: "color",
            },
            text: {
              field: "name",
            },
            fontSize: {
              value: 10,
            },
          },
        },
      },
      {
        type: "rule",
        from: {
          data: "events",
        },
        encode: {
          enter: {
            x: {
              scale: "xscale",
              field: "when",
            },
            y: {
              value: 0,
            },
            y2: {
              field: {
                group: "height",
              },
            },
            stroke: {
              field: "color",
            },
          },
        },
      },
    ],
  };
};

type PhaseVisSpec = {
  phaseLine:
    | "pre-checkout PDT"
    | "ETA at checkout"
    | "user-visible progress/ETA";
  type: "estimate" | "actual";
  name?: string;
  start: Date | string;
  end: Date | string;
  color: string;
  height: number;
};

type PhaseVis = {
  phaseLine: string;
  start: Date;
  end: Date;
  color: string;
  height: number;
  tooltip: {
    Type: string;
    Name?: string;
    Start: Date | string;
    End: Date | string;
    Duration: string;
  };
};

const phaseVis = (spec: PhaseVisSpec): PhaseVis => {
  const start =
    typeof spec.start === "string" ? new Date(spec.start) : spec.start;
  const end = typeof spec.end === "string" ? new Date(spec.end) : spec.end;
  return {
    phaseLine: spec.phaseLine,
    start,
    end,
    color: spec.color,
    height: spec.type === "actual" ? 1 : 0.5,
    tooltip: {
      Type: spec.type,
      Name: spec.name,
      Start: dateFormat(start, "HH:MM:ss"),
      End: dateFormat(end, "HH:MM:ss"),
      Duration: formatDurationInMinutes(start, end),
    },
  };
};

type PhaseColors = {
  completeColor: string;
  estimateColor: string;
};

type TimelineColors = {
  label: string;
  currentTime: string;
  preCheckoutPDT: string;
  forPhase: (
    phase: keyof typeof orderFulfillmentPhases | string,
  ) => PhaseColors;
};

const timelineColors = (theme: MantineTheme): TimelineColors => {
  const isDark = theme.colorScheme === "dark";
  const index = isDark ? 9 : 3;

  return {
    label: theme.colors.gray[isDark ? 3 : 8],
    currentTime: theme.colors.red[isDark ? 5 : 9],
    preCheckoutPDT: theme.colors.gray[index],
    forPhase: (
      phase: keyof typeof orderFulfillmentPhases | string,
    ): PhaseColors => {
      const base =
        valueOrUndefined(orderFulfillmentPhases, phase)?.baseColor || "gray";
      return {
        completeColor: theme.colors[base][index],
        estimateColor: theme.colors[base][index] + "80",
      };
    },
  };
};

const orderFulfillmentPhases = constConformingTo<
  Partial<
    Record<
      string,
      {
        name: string;
        baseColor: DefaultMantineColor;
      }
    >
  >
>()({
  queueing_for_picker: {
    name: "Queueing for Picker",
    baseColor: "yellow",
  },
  picking: {
    name: "Picking",
    baseColor: "orange",
  },
  queueing_for_rider: {
    name: "Queueing for Rider",
    baseColor: "red",
  },
  rider_preparation: {
    name: "Rider Preparing for Trip",
    baseColor: "violet",
  },
  // making_preceding_deliveries: {
  //   name: "Rider Making Preceding Deliveries",
  //   baseColor: "blue",
  // },
  riding: {
    name: "Riding",
    baseColor: "cyan",
  },
  handing_off: {
    name: "Handing-off",
    baseColor: "green",
  },
});

type DeliveryProgressEventType = keyof DeliveryProgressEvents;

const userPhaseByEndEvent: Record<
  Exclude<DeliveryProgressEventType, "created">,
  keyof typeof orderFulfillmentPhases
> = {
  picker_accepted: "queueing_for_picker",
  packed: "picking",
  riding_started: "queueing_for_rider",
  arrived: "riding",
  delivered: "handing_off",
};

type StateChange = {
  fromState?: OrderStateKey;
  toState: OrderStateKey;
  time: Date;
};

type CommercetoolsOrderStateChange = {
  from_state: OrderStateKey;
  to_state: OrderStateKey;
  timestamp: Date;
};

type OrderStateKey =
  | "order-created"
  | "order-picker-accepted"
  | "order-packed"
  | "order-rider-claimed"
  | "order-on-route"
  | "order-arrived"
  | "order-delivered"
  | "order-cancelled";

const extractStateChanges = (order: RawCTOrder): StateChange[] => {
  return [
    {
      toState: "order-created",
      time: new Date(order.createdAt),
    },
    ...(
      JSON.parse(
        order.custom.fields.stateChanges || "[]",
      ) as CommercetoolsOrderStateChange[]
    ).map((stateChange) => ({
      fromState: stateChange.from_state,
      toState: stateChange.to_state,
      time: new Date(stateChange.timestamp),
    })),
  ];
};

const renderString = (value: string): ReactNode => {
  if (!isNaN(Date.parse(value)) && value.length > 3) {
    return <DateTime date={new Date(value)} />;
  }

  if ((value as string).length > 15) {
    return (
      <Group spacing={0}>
        {value.toString()}
        <CopyButton value={value} />
      </Group>
    );
  }
  return value.toString();
};

const renderCTField = (value: any, depth: number): ReactNode => {
  switch (getObjectDepth(value)) {
    case 0:
      switch (typeof value) {
        case "number":
        case "boolean":
          return <Text p={4}>{value.toString()}</Text>;
        case "string":
          return <Group p={4}>{renderString(value)}</Group>;
        default:
          return <Text p={4}>null</Text>;
      }
    default:
      return renderObject(value, depth);
  }
};

const renderObject = (value: object, tableDepth: number): ReactNode => (
  <>
    {Object.entries(value).map(([k, v], i) => {
      return (
        <div key={k}>
          {i > 0 && <Divider variant="dashed" m={2} />}
          <Grid columns={3 - tableDepth} m={0} p={0}>
            <Grid.Col span={1} p={0}>
              <Text p={4}>{k}</Text>
            </Grid.Col>
            <Grid.Col span={2 - tableDepth} p={0}>
              {renderCTField(v, tableDepth + 1)}
            </Grid.Col>
          </Grid>
        </div>
      );
    })}
  </>
);
